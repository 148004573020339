<template>
  <div :class="is_study_information != 1?'hegder':''"
       id="app">
    <div class="loading"
         v-show="loading"></div>
    <div class="img_title" v-if="is_study_information != 1">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title">持仓数据</span>
      </div>
      <img style="width: 100%; height: 100%"
           src="../../assets/img/title_1.jpg"
           alt="" />
    </div>
    <div v-cloak
         class="data_centre">
      <div class="center">
        <div class="bg-color">
          <div class="bg-color-md">
            <form class="layui-form"
                  autocomplete="off">
              <div class="layui-form-item">
                <div class="layui-inline">
                  <label class="layui-form-label">日期</label>
                  <div class="layui-input-inline">
                    <template>
                      <el-date-picker v-model="trading_day"
                                      type="date"
                                      placeholder="选择日期"
                                      value-format="yyyy-MM-dd"
                                      :picker-options="pickerOptions"
                                      format="yyyy-MM-dd"
                                      :editable="false"
                                      :clearable="false">
                      </el-date-picker>
                    </template>
                  </div>
                </div>

                <div class="layui-inline"
                     v-show="model === 1">
                  <label class="layui-form-label">期货公司</label>
                  <div class="layui-input-inline">
                    <template>
                      <el-select placeholder="请选择"
                                 v-model="member"
                                 filterable>
                        <el-option v-for="(member, index) in members"
                                   :key="index"
                                   :label="member"
                                   :value="member">
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                </div>

                <div class="layui-inline">
                  <label class="layui-form-label">交易所</label>
                  <div class="layui-input-inline">
                    <template>
                      <el-select placeholder="请选择"
                                 v-model="exchange"
                                 filterable>
                        <el-option v-for="(exchange, index) in exchanges"
                                   :key="index"
                                   :label="exchange.name"
                                   :value="exchange.symbol">
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                </div>
                <div class="layui-inline">
                  <label class="layui-form-label">品种</label>
                  <div class="layui-input-inline">
                    <template>
                      <el-select placeholder="请选择"
                                 v-model="variety"
                                 filterable>
                        <el-option v-for="(variety, index) in varieties"
                                   :key="index"
                                   :label="variety.name"
                                   :value="variety.symbol">
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                </div>

                <div class="layui-inline"
                     v-show="model === 0">
                  <label class="layui-form-label">合约</label>
                  <div class="layui-input-inline">
                    <template>
                      <el-select placeholder="请选择"
                                 v-model="contract"
                                 filterable>
                        <el-option v-for="(contract, index) in contracts"
                                   :key="index"
                                   :label="contract.name"
                                   :value="contract.symbol">
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                </div>

                <div class="layui-inline">
                  <div class="layui-form-mid layui-word-aux">
                    <span class="text-primary">
                      &nbsp;
                      <i class="el-icon-warning"></i>
                      <a href="javascript:;"
                         @click="showDisclaimer">免责声明</a>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="clear"></div>

        <div class="history"
             v-show="model === 1"
             style="background: #fff; margin-top: 15px">
          <div style="width: 100%; padding: 15px; text-align: center">
            <div style="display: inline-block">
              <template>
                <el-select placeholder="数据对比"
                           v-model="compare"
                           filterable>
                  <el-option v-for="(member, index) in members"
                             :key="index"
                             :label="member"
                             :value="member">
                  </el-option>
                </el-select>
                <div style="margin: 0 10px; display: inline-block">
                  <el-button @click="changeChartLine(1)">只看成交量</el-button>
                  <el-button @click="changeChartLine(2)">只看持仓量</el-button>
                  <el-button @click="changeChartLine(0)">查看全部</el-button>
                  <el-button @click="showMemberData"
                             type="danger">返回会员分析</el-button>
                </div>
              </template>
            </div>
          </div>
          <div style="height: 400px; width: 100%; clear: both; background: #fff">
            <template>
              <ve-line :data="chart_data.history.data"
                       :settings="chart_settings.history.settings"
                       :judge-width="true"
                       :colors="chart_settings.colors"
                       :extend="chart_settings.history.option">
              </ve-line>
            </template>
          </div>
        </div>

        <div class="clear"></div>
        <div class="layui-tab layui-tab-card">
          <div class="layui-tab-content"
               style="min-height: 640px; padding: 0px; background-color: #fff">
            <div class="layui-tab-item layui-show">
              <div class="bg-color2"
                   style="width: 100%; margin-top: 0px; padding-top: 0px">
                <div class="pie-chart">
                  <div class="chart-block">
                    <template>
                      <ve-pie :data="chart_data.valumes.data"
                              :colors="chart_settings.colors"
                              :legend-visible="false"
                              :judge-width="true"
                              :title="chart_settings.valumes.title"
                              :data-empty="chart_data.valumes.empty">
                      </ve-pie>
                    </template>
                  </div>
                  <div class="chart-block">
                    <template>
                      <ve-pie :data="chart_data.holdBuys.data"
                              :colors="chart_settings.colors"
                              :legend-visible="false"
                              :judge-width="true"
                              :title="chart_settings.holdBuys.title"
                              :data-empty="chart_data.holdBuys.empty">
                      </ve-pie>
                    </template>
                  </div>
                  <div class="chart-block">
                    <template>
                      <ve-pie :data="chart_data.holdSells.data"
                              :colors="chart_settings.colors"
                              :legend-visible="false"
                              :judge-width="true"
                              :title="chart_settings.holdSells.title"
                              :data-empty="chart_data.holdSells.empty">
                      </ve-pie>
                    </template>
                  </div>
                </div>

                <div class="data-table">
                  <table class="layui-table"
                         lay-skin="line">
                    <thead>
                      <tr>
                        <th>名次</th>
                        <th>名称</th>
                        <th @click="order(0, 0)"
                            class="sort">
                          成交量 <i class="el-icon-arrow-down"></i>
                        </th>
                        <th @click="order(0, 1)"
                            class="sort">
                          增减 <i class="el-icon-sort"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in valumes"
                          :key="index">
                        <td>
                          <span :class="index < 3 ? 'badge badge-red' : 'badge'">{{ index + 1 }}</span>
                        </td>
                        <td>
                          <a href="javascript://"
                             @click="model === 0 && showVarietyData(item.name)">{{ item.name }}</a>
                        </td>
                        <td>{{ item.data }}</td>
                        <td>
                          <span :class="
                              item.change >= 0 ? 'text-red' : 'text-green'
                            ">
                            {{ item.change }}
                            <i :class="[
                                item.change >= 0
                                  ? 'el-icon-top'
                                  : 'el-icon-bottom',
                              ]"></i>
                          </span>
                        </td>
                      </tr>
                      <tr v-show="valumes.length === 0">
                        <td colspan="4">暂无数据</td>
                      </tr>
                      <tr class="bold">
                        <td>总计</td>
                        <td></td>
                        <td>{{ valumes.totalValue }}</td>
                        <td>{{ valumes.totalChanges }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="layui-table"
                         lay-skin="line">
                    <thead>
                      <tr>
                        <th>名次</th>
                        <th>名称</th>
                        <th @click="order(1, 0)"
                            class="sort">
                          多单持仓 <i class="el-icon-arrow-down"></i>
                        </th>
                        <th @click="order(1, 1)"
                            class="sort">
                          增减 <i class="el-icon-sort"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in holdBuys"
                          :key="index">
                        <td>
                          <span :class="index < 3 ? 'badge badge-red' : 'badge'">{{ index + 1 }}</span>
                        </td>
                        <td>
                          <a href="javascript://"
                             @click="model === 0 && showVarietyData(item.name)">{{ item.name }}</a>
                        </td>
                        <td>{{ item.data }}</td>
                        <td>
                          <span :class="
                              item.change >= 0 ? 'text-red' : 'text-green'
                            ">
                            {{ item.change }}
                            <i :class="[
                                item.change >= 0
                                  ? 'el-icon-top'
                                  : 'el-icon-bottom',
                              ]"></i>
                          </span>
                        </td>
                      </tr>
                      <tr v-show="holdBuys.length === 0">
                        <td colspan="4">暂无数据</td>
                      </tr>
                      <tr class="bold">
                        <td>总计</td>
                        <td></td>
                        <td>{{ holdBuys.totalValue }}</td>
                        <td>{{ holdBuys.totalChanges }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="layui-table"
                         lay-skin="line">
                    <thead>
                      <tr>
                        <th>名次</th>
                        <th>名称</th>
                        <th @click="order(2, 0)"
                            class="sort">
                          空单持仓 <i class="el-icon-arrow-down"></i>
                        </th>
                        <th @click="order(2, 1)"
                            class="sort">
                          增减 <i class="el-icon-sort"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in holdSells"
                          :key="index">
                        <td>
                          <span :class="index < 3 ? 'badge badge-red' : 'badge'">{{ index + 1 }}</span>
                        </td>
                        <td>
                          <a href="javascript://"
                             @click="model === 0 && showVarietyData(item.name)">{{ item.name }}</a>
                        </td>
                        <td>{{ item.data }}</td>
                        <td>
                          <span :class="
                              item.change >= 0 ? 'text-red' : 'text-green'
                            ">
                            {{ item.change }}
                            <i :class="[
                                item.change >= 0
                                  ? 'el-icon-top'
                                  : 'el-icon-bottom',
                              ]"></i>
                          </span>
                        </td>
                      </tr>
                      <tr v-show="holdSells.length === 0">
                        <td colspan="4">暂无数据</td>
                      </tr>
                      <tr class="bold">
                        <td>总计</td>
                        <td></td>
                        <td>{{ holdSells.totalValue }}</td>
                        <td>{{ holdSells.totalChanges }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div>
                  <p style="text-align: center">
                    因交易所只公布前20名数据，总计数据非整体市场总计数据，敬请留意！！！
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="layer_mzsm"
         style="display: none">
      <div class="mzsm1">
        <h2>免责声明</h2>
        <p>
          除非另有说明，本数据分析系统的著作权属恒银期货有限公司。未经恒银期货有限公司书面授权，任何人不得更改或以任何方式发送、复制或传播此数据分析系统的全部或部分材料、内容。除非另有说明，此数据分析系统中使用的所有商标、服务标记及标记均为恒银期货有限公司的商标、服务标记及标记。恒银期货有限公司不会故意或有针对性地将本系统提供给任何有限制或有可能导致恒银期货有限公司违法的国家、地区或其它法律管辖区域。
        </p>
        <p>
          恒银期货有限公司对本数据分析系统的服务及其内容的准确性、完整性不做任何形式的担保。由于互联网以及卫星通讯方面的各个因素，恒银期货有限公司不担保服务的可靠性，对服务的及时性、安全性、出错发生均不作担保。此数据分析系统所载的全部内容仅作参考之用，不构成对任何人的投资建议，且恒银期货有限公司不因接收人收到此数据分析的内容而视其为客户。
        </p>
        <p>
          此数据分析系统服务可能不适合阁下，我们建议阁下如有任何疑问应咨询独立投资顾问。此数据分析系统服务不构成投资、法律、会计或税务建议，且不担保任何投资及策略适合阁下。此数据分析系统服务并不构成给予阁下的私人咨询建议。
        </p>
        <p>恒银期货有限公司版权所有并保留一切权利。</p>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import VePie from "v-charts/lib/pie.common";
import VeLine from "v-charts/lib/line.common";
import moment from "moment";
export default {
  name:'hold_a_position',
  props:['is_study_information'],
  components: {
    [VePie.name]: VePie,
    [VeLine.name]: VeLine,
  },
  data () {
    return {
      loading: true,
      model: 0,
      defaultItem: {
        name: "--- 所有 ---",
        symbol: "all",
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        },
      },
      members: [],
      exchanges: [],
      varieties: [],
      contracts: [],
      member: "",
      compare: "",
      exchange: "",
      variety: "",
      contract: "",
      trading_day: "",
      result: [],
      history: [],
      chart_settings: {
        colors: [
          "#c23531",
          "#2f4554",
          "#61a0a8",
          "#d48265",
          "#91c7ae",
          "#749f83",
          "#ca8622",
          "#bda29a",
          "#6e7074",
          "#546570",
          "#c4ccd3",
        ],
        valumes: {
          title: {
            text: "成交量结构",
            left: "center",
            top: "15px",
          },
          data: {
            columns: ["name", "data"],
            rows: [],
          },
        },
        holdBuys: {
          title: {
            text: "多单持仓比例",
            left: "center",
            top: "15px",
          },
          data: {
            columns: ["name", "data"],
            rows: [],
          },
        },
        holdSells: {
          title: {
            text: "空单持仓比例",
            left: "center",
            top: "15px",
          },
          data: {
            columns: ["name", "data"],
            rows: [],
          },
        },
        history: {
          settings: {
            xAxisType: "time",
            labelMap: {
              valumes: "成交量",
              holdBuys: "多单持仓量",
              holdSells: "空单持仓量",
            },
            yAxisName: ["成交量", "持仓量"],
            axisSite: { right: ["holdBuys", "holdSells"] },
          },
          option: {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  formatter: function (params) {
                    console.log(params)
                    return (
                      (params.seriesData.length ? params.seriesData[0].data[0] : params.value.toFixed(2))
                    );
                  }
                }
              },
              textStyle: {
                align: "left",
              },
            },
            yAxis: {
              show: true,
              axisLine: {
                show: true,
              },
            },
            xAxis: {
              splitLine: {
                show: false,
              },
              splitNumber: 10,
              axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                formatter: function (data, index) {
                  return moment(data).format("YYYYMMDD");
                },
              },
            },
            series: {
              showSymbol: false,
            },
          },
          data: {
            columns: ["date", "valumes", "holdBuys", "holdSells"],
            rows: [],
          },
        },
      },
    };
  },
  computed: {
    //计算属性
    data_url: function () {
      return this.model === 0
        ? "/api/web/tx/open_interests"
        : "/api/web/tx/stat_by_company";
    },
    valumes: function () {
      return this.topN(this.result["valumes"], 20);
    },
    holdBuys: function () {
      return this.topN(this.result["holdBuys"], 20);
    },
    holdSells: function () {
      return this.topN(this.result["holdSells"], 20);
    },
    chart_data: function () {
      var $this = this;
      return {
        valumes: {
          empty: $this.valumes.length === 0,
          data: {
            columns: $this.chart_settings.valumes.data.columns,
            rows: $this.valumes,
          },
        },
        holdBuys: {
          empty: $this.holdBuys.length === 0,
          data: {
            columns: $this.chart_settings.holdBuys.data.columns,
            rows: $this.holdBuys,
          },
        },
        holdSells: {
          empty: $this.holdSells.length === 0,
          data: {
            columns: $this.chart_settings.holdSells.data.columns,
            rows: $this.holdSells,
          },
        },
        history: {
          data: {
            columns: $this.chart_settings.history.data.columns,
            rows: $this.history,
          },
        },
      };
    },
  },
  created: function () {
    this.$axios({
      method: "get",
      headers: {
        'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
      },
      url: `/api/web/tx/last_open_date`,
    }).then((res) => {
      this.trading_day = res.data.data;
    });

    this.getExchanges();
    this.getMembers();
    this.getData();
  },
  mounted () {
    this.loading = false;
    $(function () {
      $("#cf-app, #cf-weixin").hover(
        function () {
          $(this).find("img").show();
        },
        function () {
          $(this).find("img").hide();
        }
      );
    });
  },
  watch: {
    model: function () {
      this.getData();
    },
    trading_day: function () {
      this.contract = "";
      this.getData();
      this.variety !== "" && this.getContracts();
    },
    member: function () {
      this.compare = "";
      this.getHistroy();
      this.getData();
    },
    compare: function () {
      if (this.compare === this.member) {
        this.compare = "";
        this.$alert("请选择不同的期货公司进行对比！", "操作失败", {
          type: "warning",
        });
      }
      this.compare !== "" && this.getHistroy(1);
    },
    exchange: function () {
      this.variety = this.contract = "";
      this.getVarieties();
      this.exchange !== "" && this.getData();
    },
    variety: function () {
      this.contract = "";
      this.getContracts();
      this.variety !== "" && this.getData();
    },
    contract: function () {
      this.contract !== "" && this.getData();
    },
  },
  methods: {
    clean (param) {
      if (typeof param === "undefined" || param === "" || param === "all") {
        return "";
      }
      return param;
    },
    getMembers: function () {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/companies`,
      }).then((res) => {
        this.members = res.data.data;
      });
    },
    getExchanges: function () {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/exchanges`,
      }).then((res) => {
        this.exchanges = res.data.data.map(function (item) {
          return {
            name: item["name"],
            symbol: item["id"],
          };
        });
        this.exchanges.unshift(this.defaultItem);
      });
    },
    getVarieties: function () {
      if (this.exchange === "" || this.exchange === "all") {
        this.varieties = [];
      } else {
        this.$axios({
          method: "get",
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
          url: `/api/web/tx/varieties?exchangeId=${this.exchange}`,
        }).then((res) => {
          this.varieties = res.data.data.map(function (item) {
            return { name: item["name"], symbol: item["id"] };
          });
          this.varieties.unshift(this.defaultItem);
        });
      }
    },
    getContracts: function () {
      if (
        this.tradingDay === "" ||
        this.variety === "" ||
        this.variety === "all"
      ) {
        this.contracts = [];
      } else {
        this.$axios({
          method: "get",
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
          url: `/api/web/tx/contracts?varietyId=${this.variety}`,
        }).then((res) => {
          this.contracts = res.data.data.map(function (item) {
            return { name: item.name, symbol: item.id };
          });
          this.contracts.unshift(this.defaultItem);
        });
      }
    },
    getData: function () {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: this.data_url,
        params: {
          ddate: this.trading_day,
          stockExchangeId: this.clean(this.exchange),
          varietyId: this.clean(this.variety),
          contractId: this.clean(this.contract),
          company: this.clean(this.member),
          needDetail: true
        },
      }).then((res) => {
        var result = res.data;
        if (result["success"]) {
          if (result["data"].details) {
            let arr1 = [];
            let arr2 = [];
            let arr3 = [];
            result["data"].details.holdBuy.forEach((item, index) => {
              if (item.name !== '总计') {
                arr1.push({ ...item, name: item.varietyName ? item.varietyName : item.name ? item.name : item.contract })
              }
            })
            result["data"].details.holdSell.forEach((item, index) => {
              if (item.name !== '总计') {
                arr2.push({ ...item, name: item.varietyName ? item.varietyName : item.name ? item.name : item.contract })
              }
            })
            result["data"].details.volume.forEach((item, index) => {
              if (item.name !== '总计') {
                arr3.push({ ...item, name: item.varietyName ? item.varietyName : item.name ? item.name : item.contract })
              }
            })
            this.result = {
              holdBuys: arr1,
              holdSells: arr2,
              valumes: arr3,
            };
          } else {
            this.result = result["data"];
          }
        } else {
          // throw Error();
        }
      });
      if (this.model === 1) {
        this.getHistroy();
      }
    },
    getHistroy: function (c) {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/stat_by_company`,
        params: {
          ddate: this.trading_day,
          stockExchangeId: this.clean(this.exchange),
          varietyId: this.clean(this.variety),
          company: this.clean(c === 1 ? this.compare : this.member),
          needDetail: false
        },
      }).then((res) => {
        var data = {
          holdBuys: res.data.data.history.holdBuy,
          holdSells: res.data.data.history.holdSell,
          valumes: res.data.data.history.volume,
        };
        var result = [];
        var len = data.valumes.length;
        if (c === 1) {
          for (var i = 0; i < len; i++) {
            this.chart_settings.history.settings.labelMap = {
              valumes: "成交量[" + this.member + "]",
              holdBuys: "多单持仓量[" + this.member + "]",
              holdSells: "空单持仓量[" + this.member + "]",
              volume1: "成交量[" + this.compare + "]",
              long1: "多单持仓量[" + this.compare + "]",
              short1: "空单持仓量[" + this.compare + "]",
            };
            this.chart_settings.history.data.columns = [
              "ddate",
              "valumes",
              "holdBuys",
              "holdSells",
              "volume1",
              "long1",
              "short1",
            ];
            this.history[i].volume1 = data.valumes[i].data;
            this.history[i].long1 = data.holdBuys[i].data;
            this.history[i].short1 = data.holdSells[i].data;
          }
        } else {
          for (var i = 0; i < len; i++) {
            result.push({
              ddate: data.valumes[i].ddate,
              valumes: data.valumes[i].data,
              holdBuys: data.holdBuys[i].data,
              holdSells: data.holdSells[i].data,
            });
          }
          this.chart_settings.history.settings.labelMap = {
            valumes: "成交量[" + this.member + "]",
            holdBuys: "多单持仓量[" + this.member + "]",
            holdSells: "空单持仓量[" + this.member + "]",
          };
          this.chart_settings.history.data.columns = [
            "ddate",
            "valumes",
            "holdBuys",
            "holdSells",
          ];
          this.history = result;
        }
      });
    },
    topN: function (arr, n) {
      var result = [];
      var totalValue = 0;
      var totalChanges = 0;
      arr && arr.forEach(function (item, index) {
        if (index < n) {
          result.push(item);
        }
        if (index === n) {
          result.push(item);
        }
        // if (index > n) {
        //   result[20].change += item.change;
        //   result[20].data += item.data;
        // }
        totalValue += item.data;
        totalChanges += item.change;
      });
      result.totalValue = totalValue;
      result.totalChanges = totalChanges;

      return result;
    },
    changeChartLine: function (i) {
      if (this.compare !== "") {
        if (i === 1) {
          this.chart_settings.history.data.columns = [
            "ddate",
            "valumes",
            "volume1",
          ];
        }
        if (i === 2) {
          this.chart_settings.history.data.columns = [
            "ddate",
            "holdBuys",
            "holdSells",
            "long1",
            "short1",
          ];
        }
        if (i === 0) {
          this.chart_settings.history.data.columns = [
            "ddate",
            "valumes",
            "holdBuys",
            "holdSells",
            "volume1",
            "long1",
            "short1",
          ];
        }
      } else {
        if (i === 1) {
          this.chart_settings.history.data.columns = ["ddate", "valumes"];
        }
        if (i === 2) {
          this.chart_settings.history.data.columns = [
            "ddate",
            "holdBuys",
            "holdSells",
          ];
        }
        if (i === 0) {
          this.chart_settings.history.data.columns = [
            "ddate",
            "valumes",
            "holdBuys",
            "holdSells",
          ];
        }
      }
    },
    order: function (i, j) {
      var item = i === 0 ? "valumes" : i === 1 ? "holdBuys" : "holdSells";
      var data = this.result[item];
      var obj = data.pop();
      data.order = data.order || 0;

      //变化值直接反序
      if (data.order === j && j === 1) {
        data.reverse();
      }
      if (data.order !== j) {
        data.sort(function (a, b) {
          if (j === 0) {
            return b.data - a.data;
          } else {
            return b.change - a.change;
          }
        });
      }
      data.push(obj);

      data.order = j;
    },
    showVarietyData: function (member) {
      this.model = 1;
      this.member = member;
    },
    showMemberData: function () {
      this.model = 0;
    },
    showDisclaimer: function () {
      this.$alert($(".layer_mzsm").html(), {
        dangerouslyUseHTMLString: true,
      });
    },
  },
};
</script>
<style scoped src='../../assets/css/index.css'></style>
<style scoped>
.layui-table th,
.layui-table td {
  text-align: center !important;
  padding: 10px 10px !important;
}
.hegder {
  background: #f2f2f2;
  margin-bottom: -76px;
  padding-bottom: 76px;
}

.layui-form-item {
  margin-bottom: 0px;
}

.layui-inline {
  display: inline-block;
}

.layui-form-label {
  display: inline-block;
}

.layui-input-inline {
  display: inline-block;
  width: 190px;
}

.el-input {
  width: 100% !important;
}

.layui-table {
  float: left;
  width: 375px;
  margin: 0 5px 50px 5px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  color: #666;
}

.layui-table th,
.layui-table td {
  border: solid #e2e2e2;
  border-width: 1px 0 1px 0;
  text-align: center;
  padding: 10px 15px;
}

.layui-table tr > td:nth-child(3),
.layui-table tr > td:nth-child(4) {
  text-align: right;
  padding-right: 20px;
}

.layui-table th {
  background: #f2f2f2;
}

.layui-table a {
  font-weight: unset;
  color: #666;
  text-decoration: none;
}

.layui-table a:hover {
  color: #dc1f1f;
}

.layui-tab.layui-tab-card {
  margin-top: 15px;
}

.badge {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding: 0;
  margin: 0;
  display: inline-block;
  color: #999;
  background: #eee;
  border-radius: 50%;
}

.badge-red {
  color: #fff;
  background: #dc1f1f;
}

.bold {
  font-weight: bold;
}

.sort {
  cursor: pointer;
}

.chart-block {
  float: left;
  width: 375px;
  margin: 20px 5px 10px 5px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
}

.loading {
  width: 1200px;
  height: 720px;
  margin: 30px auto 0 auto;
  /* background: url("../../resources/images/loading.gif") center no-repeat #fff; */
}

.text-red {
  color: #dc1f1f;
}

.text-green {
  color: #0bb20c;
}

.layer_mzsm h2 {
  text-align: center;
}

.layer_mzsm p {
  text-indent: 2em;
}

[v-cloak] {
  display: none;
}

.banner {
  /* background: url("/e-futures/resources/images/web/ybbg.jpg") center center !important; */
  height: 150px !important;
  min-height: 150px !important;
}
.layer_mzsm {
  padding: 15px;
}

.layer_mzsm p {
  text-indent: 2em;
  font-size: 14px;
  line-height: 30px;
}

.mzsm1 h2 {
  text-align: center;
  padding: 0 0 15px 0;
}

.mzsm1 p {
  text-indent: 2em;
  line-height: 30px;
}
.hegder .layui-form-label {
  padding: 9px 10px;
  width: auto;
}
.hegder .layui-form-item {
  margin-bottom: 0px;
}
.hegder .real-time-data {
  margin: 30px 0px;
}
.hegder .real-time-data .remark {
  padding-right: 5px;
  text-align: right;
}
.hegder .real-time-data ul li {
  padding: 0 10px;
  border-right: 1px solid #ddd;
}
.hegder .hqChart {
  width: 100%;
  height: 500px;
}
.hegder .jcChart {
  width: 100%;
  height: 300px;
}

ol,
ul,
li,
li {
  list-style: none;
}

.clear {
  clear: both;
  line-height: 0px;
  font-size: 0;
  height: 0;
}
.center {
  width: 1200px;
  margin: 0px auto;
}
.banner {
  /* background: url(../images/taobao2_02.jpg) no-repeat center; */
  width: auto;
  height: 350px;
}
.bg-color {
  background: #fff;
  height: auto;
  padding: 20px;
  margin-top: 30px;
}
.bg-color .bg-border {
  height: 2px;
  background: #00428f;
}
.bg-color ul.top-tab li {
  float: left;
  width: 202px;
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  font-weight: 700;
}
.bg-color ul.top-tab .li-active {
  /* background: url(../images/meun.png) no-repeat; */
  width: 202px;
  height: 44px;
  color: #fff !important;
}
.bg-color ul.top-tab .li-active a {
  color: #fff !important;
}
.bg-color .bg-color-md {
  margin: 30px 0 20px 0px;
}
.bg-color label {
  margin-left: 5px;
  margin-right: 5px;
}
.bg-color a {
  color: #064b9c;
  text-decoration: none;
}
.bg-color a:visited {
  color: #064b9c;
  text-decoration: none;
}
.bg-color a:hover {
  color: #c00;
  text-decoration: underline;
}
.bg-color2 {
  background: #fff;
  min-height: 758px;
  height: auto;
  padding: 20px;
  margin-top: 20px;
  float: left;
  width: 810px;
}
.bg-color2 h2 {
  font-size: 20px;
  color: #064b9c;
  line-height: 20px;
  margin-top: 0;
}
.bg-color3 {
  height: auto;
  width: 370px;
  float: right;
}
.bg-color4 {
  background: #fff;
  height: auto;
  width: 370px;
  padding: 5px 15px 5px 15px;
  margin-top: 20px;
}
.bg-color4 ul li {
  color: #c8c8c8;
  line-height: 40px;
  /* background: url(../images/dian_05.png) no-repeat 0px 18px; */
  padding-left: 15px;
}
.bg-color4 ul {
  text-align: right;
  font-size: 12px;
  padding: 0;
}
.bg-color4 i {
  margin-right: 10px;
}
.bg-color4 a {
  color: #666;
  text-decoration: none;
  font-size: 13px;
  float: left;
}
.bg-color4 a:visited {
  color: #666;
}
.bg-color4 a:hover {
  color: #e60e0e;
  text-decoration: underline;
}
.bg-color4 h4 {
  font-size: 16px;
  color: #064b9c;
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 0;
}
.bg-color4 p {
  font-size: 13px;
  color: #666;
  line-height: 32px;
}
.bg-color4 strong {
  color: #333;
}
.bg-color5 {
  background: #fff;
  height: auto;
  width: 370px;
  padding: 5px 15px 10px 15px;
  margin-top: 25px;
}
.bg-color5 ul li {
  color: #c8c8c8;
  line-height: 40px;
  /* background: url(../images/dian_05.png) no-repeat 0px 18px; */
  padding-left: 15px;
}
.bg-color5 ul {
  text-align: right;
  font-size: 12px;
  padding: 0;
}
.bg-color5 i {
  margin-right: 10px;
}
.bg-color5 a {
  color: #666;
  text-decoration: none;
  font-size: 13px;
  float: left;
}
.bg-color5 a:visited {
  color: #666;
}
.bg-color5 a:hover {
  color: #e60e0e;
  text-decoration: underline;
}
.bg-color5 h4 {
  font-size: 16px;
  color: #064b9c;
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 0;
}
.bg-color5 p {
  font-size: 13px;
  color: #666;
  line-height: 30px;
}
.bg-color5 strong {
  color: #333;
}
.bg-color6 {
  background: #fff;
  height: auto;
  width: 370px;
  padding: 5px 15px 10px 15px;
  margin-top: 20px;
}
.bg-color6 ul li {
  color: #c8c8c8;
  line-height: 40px;
  /* background: url(../images/dian_05.png) no-repeat 0px 18px; */
  padding-left: 15px;
}
.bg-color6 ul {
  text-align: right;
  font-size: 12px;
  padding: 0;
}
.bg-color6 i {
  margin-right: 10px;
}
.bg-color6 a {
  color: #666;
  text-decoration: none;
  font-size: 13px;
  float: left;
}
.bg-color6 a:visited {
  color: #666;
}
.bg-color6 a:hover {
  color: #e60e0e;
  text-decoration: underline;
}
.bg-color6 h4 {
  font-size: 16px;
  color: #064b9c;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 15px;
}
.bg-color6 p {
  font-size: 13px;
  color: #666;
  line-height: 30px;
}
.bg-color6 strong {
  color: #333;
}
.bg-color6 .col-md-4 {
  padding-right: 10px;
  padding-left: 15px;
}
.bg-color6 input {
  width: 80%;
}
.bg-color6 span {
  float: right;
  margin-top: -20px;
}
.bg-color6 select {
  width: 80%;
}
.bg-color6 .bg6-border {
  height: 1px;
  background: #dfdfdf;
  margin: 15px 0 15px 0;
}
.bg-color6 .bg6-text {
  text-align: center;
}
.bg-color7 {
  background: #fff;
  min-height: 758px;
  height: auto;
  padding: 20px;
  line-height: 36px;
}
.bg-color7 h2 {
  font-size: 20px;
  color: #064b9c;
  line-height: 20px;
  margin-top: 0;
}
.bg-color7 button {
  margin-left: 0;
  width: 40%;
  height: 46px;
  margin-left: -5px;
  margin-top: 10px;
}
.bg-color7 i {
  font-size: 16px;
  color: #999;
  padding-right: 10px;
}
.bg-color7 input {
  height: 42px;
  line-height: 42px;
}
.bg-color7 select {
  height: 42px;
  line-height: 42px;
}
.footer {
  border-top: 1px solid #ebebeb;
  font-size: 13px;
  text-align: center;
  height: 130px;
  line-height: 30px;
  padding: 20px 0;
}
.img_title {
  width: 100%;
  background: #ccc;
}
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
</style>
